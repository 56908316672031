<template>
  <div class="container">
    <div class="handle-box">
      <el-button type="success" icon="el-icon-plus" @click="onAdd">
        添加
      </el-button>
      <el-button type="danger" icon="el-icon-delete" @click="delAll">
        批量删除
      </el-button>
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      row-key="id"
      :tree-props="{ children: 'childrenList', hasChildren: 'hasChildren' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column label="菜单标题">
        <template slot-scope="scope">
          <i class="el-icon-menu"></i>
          <span style="margin-left: 10px">{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="菜单编号" prop="code" />
      <el-table-column label="菜单图标" prop="icon" />
      <el-table-column label="菜单URL" prop="url" />
      <el-table-column label="序号" prop="xh" />
      <el-table-column label="激活状态" prop="isActive">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isActive"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            @change="changeSwitch($event, scope.row, scope.$index)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="onEdit(scope.row)">
            编辑
          </el-button>
          <el-button type="danger" size="small" @click="onDel(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog
      title="编辑信息"
      width="780px"
      :visible.sync="selectDlgShow"
      :close-on-click-modal="false"
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form" label-width="100px">
          <el-form-item label="上级菜单" prop="pid">
            <el-cascader
              v-model="form.pid"
              :options="pmenuList"
              clearable
              :show-all-levels="false"
              :props="{
                value: 'id',
                label: 'title',
                children: 'childrenList',
                emitPath: false,
                checkStrictly: true
              }"
              placeholder="请选择上级部门"
            >
            </el-cascader>
            <span class="demo-tip">不填默认未顶级菜单</span>
          </el-form-item>
          <el-form-item label="菜单标题" prop="title" required>
            <el-input v-model="form.title" clearable />
          </el-form-item>
          <el-form-item label="菜单编号" prop="code" required>
            <el-input v-model="form.code" clearable></el-input>
          </el-form-item>
          <el-form-item label="菜单图标" prop="icon">
            <el-input v-model="form.icon" clearable></el-input>
          </el-form-item>
          <el-form-item label="菜单URL" prop="url">
            <el-input v-model="form.url" type="text" clearable />
          </el-form-item>
          <el-form-item label="序号" prop="xh">
            <el-input v-model="form.xh" type="number" clearable />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" plain @click="selectDlgShow = false">
          关 闭
        </el-button>
        <el-button size="small" @click="resetForm('ruleForm')">重 置</el-button>
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">
          保 存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMenuList,
  saveMenu,
  delMenu,
  updateIsActive
} from '@/api/app/appMenu';

export default {
  data() {
    return {
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0
      },
      form: {
        id: '',
        pid: '',
        title: '',
        code: '',
        icon: '',
        url: '',
        xh: '',
        isActive: 1
      },
      pmenuList: [],
      list: [],
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    loadMenuList() {
      this.pmenuList = [];
      getMenuList().then(response => {
        console.log(response.data);
        this.pmenuList = response.data;
      });
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
    },
    delAll() {
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delMenu({ ids: ids }).then(resp => {
          this.$message({
            type: 'success',
            message: resp.msg
          });
          this.fetchData();
        });
      });
    },
    onDel(row) {
      this.$confirm('您确定要删除此用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delMenu({ id: row.id }).then(resp => {
          this.$message({
            type: 'success',
            message: resp.msg
          });
          this.fetchData();
        });
      });
    },
    fetchData() {
      this.listLoading = true;
      getMenuList(this.pageInfo).then(response => {
        response.data.sort((a, b) => a.xh - b.xh);
        this.list = response.data.map(mapper);
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });

      function mapper(item) {
        item.childrenList?.sort((a, b) => a.xh - b.xh);
        item.childrenList?.map(mapper);
        return item;
      }
    },
    changeSwitch(data, b,) {
      //开关切换
      var id = b.id;
      var isActive = b.isActive;
      updateIsActive({ id: id, status: isActive }).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '状态修改成功',
            type: 'success'
          });
        } else {
          this.$message.error(resp.msg);
        }
      });
    },
    onAdd() {
      this.loadMenuList();
      this.form = {
        id: '',
        pid: '',
        title: '',
        code: '',
        icon: '',
        url: '',
        xh: ''
      };
      this.selectDlgShow = true;
    },
    onEdit(row) {
      this.loadMenuList();
      this.form = {
        id: row.id,
        pid: row.pid,
        title: row.title,
        code: row.code,
        icon: row.icon,
        url: row.url,
        xh: row.xh
      };
      this.selectDlgShow = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    save() {
      this.dlgLoading = true;
      saveMenu(this.form)
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.selectDlgShow = false;
            this.dlgLoading = false;
            this.fetchData();
          } else {
            this.$message.error('保存失败');
          }
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.upload-file {
  display: inline-block;
  margin-right: 10px;
}
.red {
  color: red;
}
</style>
